.App {
  text-align: center;
  max-width: 1024px;
  margin: auto
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Project block hover effect */
.project-block {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.project-block:hover {
  transform: translateY(-4px);
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.2),
    0 6px 6px rgba(0, 0, 0, 0.1);
}

/* Skill block hover effects */
.skill-block {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.skill-block:hover {
  transform: translateY(-4px);
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.2),
    0 6px 6px rgba(0, 0, 0, 0.1);
}

/* Active state for better interaction feedback */
.skill-block:active,
.project-block:active {
  transform: translateY(-2px);
  box-shadow: 
    0 5px 10px rgba(0, 0, 0, 0.2),
    0 3px 3px rgba(0, 0, 0, 0.1);
  transition-duration: 0.1s;
}

/* Follow X button effect */
.follow-button {
  position: relative;
  background: rgb(3 105 161);
  transition: all 0.3s ease;
  z-index: 1;
}

.follow-button::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(90deg, #00d2ff, #3a7bd5, #00d2ff);
  background-size: 200% 100%;
  animation: moveGradient 2s linear infinite;
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.follow-button:hover::before {
  opacity: 1;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
